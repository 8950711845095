
import Link from "next/link";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Card } from 'antd';
import {
    approvePatient
} from "../lib/helper/patient";
 
import { Amplify, Auth, Hub } from "aws-amplify";
import { toast, ToastContainer } from "react-toastify";
import { NextSeo } from "next-seo";
import Image from "next/image"

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: "us-east-2",

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: "us-east-2_cgNtBq17o",
        //userPoolId: "us-east-2_ygEEuJ9rg",

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: "2bece7m81kfgsrtjjtsvjuvbd8",
        //userPoolWebClientId: "42coq7iutjmqjf5n4jodrn5agr",
        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: "poshdoc-patients.auth.us-east-2.amazoncognito.com",
            //domain: "nextmed-patients-new.auth.us-east-2.amazoncognito.com",
            //scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            scope: ["email", "profile", "openid"],
            redirectSignIn: "https://www.poshdoc.com/login",
            //redirectSignOut: 'http://localhost:3001/',
            responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
});



var cognitoUser = CognitoUser;
const Home = (props) => {
    const router = useRouter();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [otp, setOtp] = useState(false);
    const [resendBtn, setResendBtn] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [text_message, setText_message] = useState({
        head: null,
        para: null
    })
    const [username, setUsername] = useState("");
    const [answer, setAnswer] = useState("");
    const [subHeader, setSubHeader] = useState("Log in to your Poshdoc platform account to continue");

    useEffect(() => {
        if (router.query?.default) {
            handleChangeSubHeader()
        }
        if (router.query?.email && router.query?.otp) {
            setOtp(true)
            setUsername(router.query.email)
            autoLogin(router.query.email, router.query.otp)
        }
        if (props && props.success && props.success === "true") {
            setText_message((prev) => {
                return {
                    ...prev,
                    head: "Thanks for your order!",
                    para: "Login and fill your intake form to move on to your doctor visit and lab test."
                }
            })
        }
        if (
            window.sessionStorage.getItem("isLoggedIn") === "true" &&
            window.sessionStorage.getItem("token")
        ) {
            router.push({
                pathname: "/visits",
                query: {
                    ...router.query,
                }
            });
        }


        if (props.load_page != undefined) {
            if (props.load_page == "cc" || props.load_page == "wl") {
                Cookies.set("load_page", props.load_page);
            } else {
                Cookies.remove("load_page");
            }
        } else {
            Cookies.remove("load_page");
        }
    }, [])


    const autoLogin = async (email, otp) => {
        try {
            cognitoUser = await Auth.signIn(email)
            if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
                setLoginLoading(true)
                //Clean up router query email and otp 
                delete router.query.email
                delete router.query.otp
                await answerCustomChallenge(otp);
            }
        } catch (error) {
            console.log("Error signing in", error);
        }
    }

    const getCurrentUser = async (data) => {
        if (data.payload.data.signInUserSession) {
            let signInUserSession = data.payload.data.signInUserSession;

            window.sessionStorage.setItem("token", signInUserSession.idToken.jwtToken);
            window.sessionStorage.setItem(
                "name",
                signInUserSession.idToken.payload.name
            );
            window.sessionStorage.setItem(
                "email",
                signInUserSession.idToken.payload.email
            );
            window.sessionStorage.setItem("isLoggedIn", true);
        }
    };

    Hub.listen("auth", (data) => {
        if (data?.payload?.data?.message?.includes('User is disabled')) {
            setError(
                "Your account is disabled!. Please contact us through this email <a href='mailto:Team@poshdoc.com'>Team@poshdoc.com</a>"
            );
            setOtp(false);
            setAnswer("");
        }
        else if (data?.payload?.data?.code == "NotAuthorizedException") {
            setError(
                `Your one time code has expired, please resend the code and try again.`
            );
            setResendBtn(true);
            setAnswer("");
            setOtp(false);

        } else {
            switch (data.payload.event) {
                case "signIn":
                    setSuccess("Welcome Back!  ");
                case "cognitoHostedUI":
                    getCurrentUser(data);
                    break;
                case "signIn_failure":
                    setError(
                        `<p className="error-p text-danger">No account found for ${data.payload.message.split(" ")[0]}. Please call us at <a className="text-danger" href="tel:+19177467800">1-917-746-7800</a> for immediate assistance in accessing your account or email us at <a className="text-danger" href="mailto:Team@poshdoc.com">Team@poshdoc.com</a>. We may need to reset your account or update your email address</p>.
            `
                    );
                case "cognitoHostedUI_failure":
                    setError(
                        `<p className="error-p text-danger">No account found for ${data.payload.message.split(" ")[0]}. Please call us at <a className="text-danger" href="tel:+19177467800">1-917-746-7800</a> for immediate assistance in accessing your account or email us at <a className="text-danger" href="mailto:Team@poshdoc.com">Team@poshdoc.com</a>. We may need to reset your account or update your email address</p>.
           `
                    );

                    break;
                default:
                    break;
            }
        }
        setOtpLoading(false);
    });

    async function signIn(email1) {
        cognitoUser = await Auth.signIn(email1);
        if (cognitoUser) {
            setOtp(true);
            setResendBtn(false);
            setOtpLoading(false);
        } else {
            toast.warn("Please check your email!");
            setOtpLoading(false);
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setOtpLoading(true);
        if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(username.trim())) {
            setError("There is no account associated with this email. Please try another or contact us at Team@poshdoc.com");
            setOtpLoading(false);
        } else {
            signIn(username.trim());
            setError("");
        }
        setTimeout(() => {
            setOtpLoading(false);
            setLoginLoading(false);
        }, 3000);
    };

    const handleChangeSubHeader = () => {
        if (router.query.default == "refill") {
            setSubHeader("Log in to your Poshdoc platform account to request your refill")
        }
        else if (router.query.default == "membership") {
            setSubHeader("Log in to your Poshdoc platform account to manage your membership")
        }
        else if (router.query.default == "cancel") {
            setSubHeader("Log in to your Poshdoc platform account to cancel your subscription")
        }
    }

    async function answerCustomChallenge(answer) {
        cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
        try {
            const currSessionData = await Auth.currentSession();

            if (currSessionData) {
                window.sessionStorage.setItem(
                    "token",
                    currSessionData.idToken.jwtToken
                );
                window.sessionStorage.setItem(
                    "refershToken",
                    currSessionData.refreshToken.token
                );
                window.sessionStorage.setItem(
                    "acessToken",
                    currSessionData.accessToken.jwtToken
                );

                window.sessionStorage.setItem(
                    "name",
                    currSessionData.idToken.payload.name
                );
                window.sessionStorage.setItem(
                    "email",
                    currSessionData.idToken.payload.email
                );
                window.sessionStorage.setItem("isLoggedIn", true);

            } else {
                Auth.signOut()
                setResendBtn(false);
                setAnswer("");
                setOtp(false);
            }

            if (router.query.followup) {
                router.push("/followup");
            } else if (props && props.existing_user == "true") {
                router.push("/checkout");
            } else if (props.load_page == "cc") {
                router.push("/information");
            } else if (props.load_page == "wl") {
                const payload = {
                    email: currSessionData.idToken.payload.email,
                    approve: "0",
                };
                await approvePatient(payload);
            } else if (props && props.page != undefined) {
                const route = props.id ? `/${props.page}?id=${props.id}` : `/${props.page}`;
                router.push(route);
            } else {
                if (router.query.next) {
                    router.push({
                        pathname: router.query.next,
                        query: {
                            ...router.query,
                        }
                    })
                } else {

                    router.push({
                        pathname: "/visits",
                        query: {
                            ...router.query,
                        }
                    });
                }
            }

            setLoginLoading(false)
        } catch {
            setError("You didn't type the right code!");
            setLoginLoading(false)
        }
    }
    const handleOtp = (event) => {
        event.preventDefault()
        setLoginLoading(true);
        if (/\b\d{6}\b/g.test(answer)) {
            answerCustomChallenge(answer);
        } else {
            setError("Please enter the valid otp!");
            setLoginLoading(false)
        }
    };
    const resetOtp = (event) => {
        event.preventDefault()
        setLoginLoading(false);
    }

    return null

    return (
        <>
            <NextSeo
                title="Login | PoshDoc"
                description="Log in to PoshDoc securely. Enter your  email, and receive a unique password sent to your email each time you want to access your account."
                canonical="https://www.poshdoc.com/login"
            />

            <div className="login-section">
                <div className="login-page-logo !mt-4">
                    <Link href="/">
                        <div className='relative w-[100%] h-[80px]'>
                            <Image src={'/poshdoc/logo.png'} layout={'fill'} className='h-full w-full rounded-t-2xl sm:rounded-l-2xl md:rounded-l-2xl lg:rounded-l-2xl sm:rounded-r-none md:rounded-r-none lg:rounded-r-none' />

                        </div>
                    </Link>
                </div>
                <div className="section-gap">
                    <div className="container">

                        {text_message.head && (<h3 className="text-center"><p>{text_message.head}</p>
                            <b>IMPORTANT: </b>  {text_message.para}
                        </h3>)}
                        <Card bordered={true} className="login-size py-2">
                            <div className="mb-4">
                                <h3 className="text-center">Login</h3>
                                <p className="text-center" style={{ fontSize: "12.5px" }}>
                                    {subHeader}
                                </p>
                            </div>

                            <form onSubmit={(e) => { handleSubmit(e) }}>
                                <p className="sm:text-sm text-xs mb-[15px]   text-[#4d484e] font-semibold mx-auto">
                                    Your Email Address</p>
                                <input
                                    className={`lg-form-control w-100  ${error && 'highlight-error '}`}

                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    disabled={otp == true}
                                />
                                {otp == false && resendBtn == false && (

                                    <>

                                        {otpLoading == true ?

                                            <button
                                                type="submit"
                                                className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                                disabled
                                            >
                                                Loading...
                                            </button> :

                                            <button
                                                type="submit"
                                                className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                            >
                                                Get password
                                            </button>
                                        }
                                    </>
                                )}
                            </form>

                            {otp == true && resendBtn == false && (
                                <form onSubmit={(e) => { handleOtp(e) }} className="mt-3">
                                    <p className=" sm:text-sm text-xs my-[15px]  text-[#4d484e] font-semibold mx-auto">
                                        Your OTP Code</p>

                                    <input
                                        type="text"
                                        className="lg-form-control w-100"
                                        value={answer}
                                        onChange={(e) => setAnswer(e.target.value)}
                                    />
                                    {loginLoading == true ?

                                        <button
                                            className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                            type="submit"
                                            disabled
                                        >loading...
                                        </button> :

                                        <button
                                            className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                            type="submit"
                                        >Login
                                        </button>
                                    }
                                </form>
                            )}


                            {resendBtn == true && (
                                <>
                                    {otpLoading == true ?
                                        <button
                                            type="submit"
                                            className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                            disabled
                                        >
                                            Loading...
                                        </button> :
                                        <button
                                            onClick={(e) => {
                                                handleSubmit(e)
                                                resetOtp(e)
                                            }}
                                            className="btn btn-lg-primary w-38 !rounded-[25px] mt-[15px] hover:text-white hover:bg-voilet-hover"
                                        >
                                            Resend Otp
                                        </button>}
                                </>
                            )}
                            {error !== "" && (
                                <p
                                    className="text-center mt-2"
                                    style={{ color: "red", fontSize: "12px" }}
                                >
                                    <div className="main-p-div"
                                        dangerouslySetInnerHTML={{ __html: error }}
                                    />
                                </p>
                            )}
                            {success !== "" && (
                                <p
                                    className="text-center mt-2"
                                    style={{ color: "green", fontSize: "12px" }}
                                >
                                    {success}
                                </p>

                            )}

                        </Card>
                        <ToastContainer theme={"colored"} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Home;

export async function getServerSideProps(context) {
    const data = context.query;
    return {
        props: {
            existing_user: data.existing_user ? data.existing_user : null,
            load_page: data.load_page ? data.load_page : null,
            page: data.page ? data.page : null,
            success: data.success ? data.success : null,
            followup: data.followup ? data.followup : null,
            id: data.id ? data.id : null
        },
    };
}
